<template>
  <div>
   <!--  <base-header>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Usuarios</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header> -->
    <div class="container-fluid mt-4">
      <loading-overlay :active.sync="loading2" :is-full-page="true" />
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0">Listado de facturas</h3>
              </div>
              <div class="col-6 text-right">
                <base-button
                  :disabled="loading"
                  @click="openModal()"
                  type="primary"
                  icon
                  size="sm"
                >
                  <span class="btn-inner--icon"
                    ><i class="fas fa-file-excel"></i
                  ></span>
                  <span class="btn-inner--text">Exportar</span>
                </base-button>
              </div>
            </div>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap "
            >
              <el-select
                class="select-primary pagination-select py-2"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div class="py-2">
                <base-input
                  v-model="query"
                  type="search"
                  prepend-icon="fas fa-search"
                  placeholder="Buscar..."
                  clearable
                />
              </div>
            </div>
            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="facturas"
              @sort-change="sortChange"
            >
              <div class="vld-parent" slot="empty" v-if="loading">
                <loading-overlay :active.sync="loading" :is-full-page="false" />
                <img
                  src="/img/white_image.png"
                  style="height: 100px; width: 100px"
                />
              </div>
              <div slot="empty" v-if="facturas.length == 0 && !loading">
                <span>No se ha encontrado</span>
              </div>
              <el-table-column
                min-width="200px"
                label="Nombre"
                prop="name"
                sortable="custom"
              />
              <el-table-column
                min-width="200px"
                label="No. factura"
                prop="no_bill"
                sortable="custom"
              />
              <el-table-column
                min-width="200px"
                label="Fecha emisión"
                prop="bill_date"
                sortable="custom"
              />
              <el-table-column
                min-width="200px"
                label="cantidad"
                prop="quantity_sold"
                sortable="custom"
              />
              <el-table-column
                min-width="200px"
                label="Producto"
                prop="product_name"
                sortable="custom"
              />
              <el-table-column
                min-width="200px"
                label="Fase"
                prop="phase"
                sortable="custom"
              />
              <el-table-column
                min-width="200px"
                label="Creado en"
                prop="country_date"
                sortable="custom"
              />
              <el-table-column
                min-width="200px"
                label="Eliminado"
                sortable="custom"
              >
                <div slot-scope="{ row }" class="table-actions">
                  <span v-if="row.deleted == null"> No </span>
                  <span v-else> Si </span>
                </div>
              </el-table-column>
              <el-table-column
                min-width="200px"
                align="center"
                label="Link foto"
              >
                <div slot-scope="{ row }" class="table-actions">
                  <a
                    type="text"
                    :href="row.link"
                    target="_blank"
                    class="table-action"
                    style="cursor: pointer"
                  >
                    {{row.link}}
                  </a>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Mostrando {{ total ? from + 1 : 0 }} a {{ to }} de
                {{ total }} entradas
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            />
          </div>
        </card>
        <modal :show.sync="modal">
          <h6 slot="header" class="modal-title">Exportar datos</h6>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <base-input label="Fecha inicial" alternative class="mb-3">
                <flat-picker
                  slot-scope="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="config"
                  class="form-control datepicker"
                  v-model="inicio"
                >
                </flat-picker>
              </base-input>
            </div>
            <div class="col-md-6 col-sm-12">
              <base-input label="Fecha final" alternative class="mb-3">
                <flat-picker
                  slot-scope="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="config"
                  class="form-control datepicker"
                  v-model="final"
                >
                </flat-picker>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-right">
              <base-button
                :disabled="validate"
                @click="exportData()"
                type="primary"
                size="sm"
                >Exportar</base-button
              >
            </div>
            <div></div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
/* import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb"; */
import { Modal } from "@/components";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es";
import XLSX from "xlsx-color";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    /* RouteBreadCrumb, */
    Modal,
    flatPicker,
  },

  data() {
    return {
      config: {
        altFormat: "d M, Y",
        altInput: true,
        allowInput: true,
        dateFormat: "m-d-Y",
        locale: Spanish,
      },
      facturas: [],

      query: null,

      sort: "country_date",

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      loading: true,
      able: true,
      modal: false,
      inicio: new Date(),
      final: new Date(),
      loading2: false,
      routing: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    validate: function () {
      return !this.inicio || !this.final;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getBillsByUser",
      immediate: false,
      deep: true,
    },
  },
  methods: {
    openModal() {
      this.modal = true;
      this.inicio = new Date();
      this.final = new Date();
    },
    async getBillsByUser() {
      if (this.able) {
        this.loading = true;
        this.facturas = [];
        try {
          this.able = false;
          const id = this.$route.params.id;
          const params = {
            id,
            limit: this.pagination.perPage,
            page: this.pagination.currentPage,
            order: this.sort,
            search: this.query,
          };
          await this.$store.dispatch("bills/getBillsByUser", params);
          const response = this.$store.getters["bills/bills"];
          this.facturas = response.data;
          this.total = response.meta.page.total;
          this.loading = false;
          this.able = true;
        } catch (e) {
          this.loading = false;
          this.able = true;
          this.$notify({
            type: "danger",
            message: `Lo sentimos, inténtelo más tarde.`,
          });
        }
      }
    },
    formatDate(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
    async exportData() {
      this.loading2 = true;
      if (typeof this.inicio == "string") {
        this.inicio = this.inicio.split("-");
        this.inicio.forEach((e) => (e = parseInt(e)));
        this.inicio = new Date(
          this.inicio[2],
          this.inicio[0] - 1,
          this.inicio[1]
        );
        this.inicio.setHours(0, 0, 0);
        this.inicio = this.formatDate(new Date(this.inicio));
      } else {
        this.inicio.setHours(0, 0, 0);
        this.inicio = this.formatDate(new Date(this.inicio));
      }
      if (typeof this.final == "string") {
        this.final = this.final.split("-");
        this.final.forEach((e) => (e = parseInt(e)));
        this.final = new Date(this.final[2], this.final[0] - 1, this.final[1]);
        this.final.setHours(23, 59, 59);
        this.final = this.formatDate(new Date(this.final));
      } else {
        this.final.setHours(23, 59, 59);
        this.final = this.formatDate(new Date(this.final));
      }

      const params = {
        fecha_inicio: this.inicio,
        fecha_final: this.final,
        user: this.$route.params.id,
      };
      await this.$store.dispatch("bills/exportBillsByUser", params);
      const response = this.$store.getters["bills/exports_by_user"];
      if (response.length > 0) {
        let data = XLSX.utils.json_to_sheet(response, {
          header: [
            "name",
            "no_bill",
            "bill_date",
            "quantity_sold",
            "product_name",
            "phase",
            "country_date",
            "deleted_at",
            "link"
          ],
        });
        const style ={
              fill: {
                patternType: "solid",
                fgColor: { rgb: "FC9900" }
              },
              font: {
                name: 'Song Ti',
                /* sz: 12, */
                bold: true,
                color: {rgb:"FFFFFF"}
              }
          };
        data["A1"].v = "Nombre";
        data["A1"].s =style;
        data["B1"].v = "Número de factura";
        data["B1"].s =style;
        data["C1"].v = "Fecha de emisión";
        data["C1"].s =style;
        data["D1"].v = "Cantidad";
        data["D1"].s =style;
        data["E1"].v = "Producto";
        data["E1"].s =style;
        data["F1"].v = "Fase";
        data["F1"].s =style;
        data["G1"].v = "Fecha de creación";
        data["G1"].s =style;
        data["H1"].v = "Eliminado";
        data["H1"].s =style;
        data["I1"].v = "Link";
        data["I1"].s =style;
         var wscols = [];
        var temp = {a: 0,b: 0,c: 0,d: 0,e: 0,f: 0,g: 0,h: 0,i: 0};

        for (let value in data) {
          let width = data[value].v == null ? 0 : String(data[value].v).length;
          if (value[0] == "A") {width > temp.a ? temp.a = width + 2 : width; continue };
          if (value[0] == "B") {width > temp.b ? temp.b = width + 2 : width; continue };
          if (value[0] == "C") {width > temp.c ? temp.c = width + 2 : width; continue };
          if (value[0] == "D") {width > temp.d ? temp.d = width + 2 : width; continue };
          if (value[0] == "E") {width > temp.e ? temp.e = width + 2 : width; continue };
          if (value[0] == "F") {width > temp.f ? temp.f = width + 2 : width; continue };
          if (value[0] == "G") {width > temp.g ? temp.g = width + 2 : width; continue };
          if (value[0] == "H") {width > temp.h ? temp.h = width + 2 : width; continue };
          if (value[0] == "I") {width > temp.i ? temp.i = width + 2 : width; continue };
        }  
        var wscols = [
          { wch: temp.a }, // "characters"
          { wch: temp.b },
          { wch: temp.c },
          { wch: temp.d },
          { wch: temp.e },
          { wch: temp.f },
          { wch: temp.g },
          { wch: temp.h },
          { wch: temp.i },         
        ];
        data["!cols"] = wscols;

        const workbook = XLSX.utils.book_new();
        const filename = `facturas_${this.$route.params.id}`;
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      } else {
        swal.fire({
          title: `¡Atención!`,
          text: "No existen facturas para este rango de fechas.",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          icon: "warning",
        });
      }
      this.loading2 = false;
      this.modal = false;
    },
    getListDebounced: _.debounce(function () {
      this.getBillsByUser();
    }, 300),
    addBill() {
      const path = `/bills/add`;
      if (this.$route.path !== path && this.routing) {
        this.routing = false;
        this.$router.push(path);
      }
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getBillsByUser();
    },
  },
};
</script>
